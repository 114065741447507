<template>
  <div>
    <b-card>
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <div class="mt-5 mb-5 d-md-flex justify-content-center align-items-center">
          <b-col
            md="4"
            class="mb-4 mb-md-0 d-flex justify-content-center align-items-center"
          >
            <img
              class="w-50"
              src="@/assets/images/quickbook/curfox.png"
              alt="curfox_logo"
            >
          </b-col>
          <b-col
            md="1"
            class="d-flex justify-content-center align-items-center"
          >
            <img
              class="join-img"
              src="@/assets/images/quickbook/join.png"
            >
          </b-col>
          <b-col
            md="4"
            class="mt-4 mt-md-0 d-flex justify-content-center align-items-center"
          >
            <img
              class="w-50"
              src="@/assets/images/quickbook/quickbook.png"
              alt="quickbook_logo"
            >
          </b-col>
        </div>
        <div class="mt-4 mb-2 d-flex flex-column justify-content-center align-items-center">
          <b-col lg="4">
            <b-overlay
              :opacity="0.5"
              :show="loadingBtnCore"
              rounded="sm"
              blur="10px"
              class="mb-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="connectionStatus.core_status ? 'danger' : 'primary'"
                class="w-100"
                @click="!connectionStatus.core_status ? connect() : disconnect()"
              >
                {{ connectionStatus.core_status ? 'Disconnect' : 'Connect' }}
              </b-button>
            </b-overlay>
          </b-col>
          <div class="d-flex flex-wrap justify-content-center align-items-center w-100">
            <b-col lg="4">
              <b-overlay
                :opacity="0.5"
                :show="loadingBtnCustomerActivate"
                rounded="sm"
                blur="10px"
                class="mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="connectionStatus.customer_integration_status ? 'danger' : 'warning'"
                  class="w-100"
                  :disabled="!connectionStatus.core_status"
                  @click="toggleCustomerIntegrationStatus()"
                >
                  {{ connectionStatus.customer_integration_status ? 'Deactivate' : 'Activate' }} Merchant / Customer Integration
                </b-button>
              </b-overlay>
            </b-col>
            <b-col lg="4">
              <b-overlay
                :opacity="0.5"
                :show="loadingBtnSupplierActivate"
                rounded="sm"
                blur="10px"
                class="mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="connectionStatus.supplier_integration_status ? 'danger' : 'warning'"
                  class="w-100"
                  :disabled="!connectionStatus.core_status"
                  @click="toggleSupplierIntegrationStatus()"
                >
                  {{ connectionStatus.supplier_integration_status ? 'Deactivate' : 'Activate' }} Warehouse / Supplier Integration
                </b-button>
              </b-overlay>
            </b-col>
          </div>
          <div class="d-flex flex-wrap justify-content-center align-items-center w-100">
            <b-col lg="3">
              <b-overlay
                :opacity="0.5"
                :show="loadingBtnMerchant"
                rounded="sm"
                blur="10px"
                class="mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="w-100"
                  :disabled="!connectionStatus.core_status || !connectionStatus.customer_integration_status"
                  @click="syncMerchants()"
                >
                  Sync Merchants
                </b-button>
              </b-overlay>
            </b-col>
            <b-col lg="3">
              <b-overlay
                :opacity="0.5"
                :show="loadingBtnWarehouse"
                rounded="sm"
                blur="10px"
                class="mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="w-100"
                  :disabled="!connectionStatus.core_status || !connectionStatus.supplier_integration_status"
                  @click="syncWarehouses()"
                >
                  Sync Warehouses
                </b-button>
              </b-overlay>
            </b-col>
            <b-col lg="3">
              <b-overlay
                :opacity="0.5"
                :show="loadingBtnDeposit"
                rounded="sm"
                blur="10px"
                class="mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="w-100"
                  :disabled="!connectionStatus.core_status || !connectionStatus.supplier_integration_status || !connectionStatus.accounts_opened"
                  @click="syncDeposits()"
                >
                  Sync Deposits
                </b-button>
              </b-overlay>
            </b-col>
            <b-col lg="3">
              <b-overlay
                :opacity="0.5"
                :show="loadingBtnInvoice"
                rounded="sm"
                blur="10px"
                class="mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="w-100"
                  :disabled="!connectionStatus.core_status || !connectionStatus.customer_integration_status || !connectionStatus.accounts_opened"
                  @click="syncInvoices()"
                >
                  Sync Invoices
                </b-button>
              </b-overlay>
            </b-col>
          </div>
          <div class="d-flex flex-wrap justify-content-center align-items-center w-100">
            <b-col lg="4">
              <b-overlay
                :opacity="0.5"
                :show="loadingBtnSyncAccounts"
                rounded="sm"
                blur="10px"
                class="mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="'info'"
                  class="w-100"
                  :disabled="!connectionStatus.core_status"
                  @click="syncAccountsAndServices()"
                >
                  Open Accounts & Initialize Services
                </b-button>
              </b-overlay>
            </b-col>
          </div>
          <div class="text-center mt-2 mb-2">
            QuickBooks is an accounting software package developed and marketed by Intuit. First introduced in 1983, QuickBooks products are geared mainly toward small and medium-sized businesses and offer on-premises accounting applications as well as cloud-based versions that accept business payments, manage and pay bills, and payroll functions
          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
// eslint-disable-next-line import/extensions,camelcase
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const QuickBookRepository = RepositoryFactory.get('quickbook')

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BButton,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      loadingBtnMerchant: false,
      loadingBtnWarehouse: false,
      loadingBtnDeposit: false,
      loadingBtnInvoice: false,
      loadingBtnCore: false,
      loadingBtnCustomerActivate: false,
      loadingBtnSupplierActivate: false,
      loadingBtnSyncAccounts: false,
      connectionStatus: {},
    }
  },
  async mounted() {
    await this.initTokenGenerate()
    this.getConnectionStatus()
  },
  methods: {
    async connect() {
      this.loadingBtnCore = true
      const authUrl = await this.getAuthUrl()
      this.loadingBtnCore = false
      if (authUrl) {
        window.location.href = authUrl
      }
    },
    async disconnect() {
      this.loadingBtnCore = true
      try {
        const res = await QuickBookRepository.disconnect()
        if (res.status === 200) {
          await this.getConnectionStatus()
          this.showSuccessMessage('QuickBooks Disconnected Successfully')
        } else {
          this.showErrorMessage('Couldn\'t disconnect from QuickBooks. Please try again.')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnCore = false
    },
    async getConnectionStatus() {
      this.loading = true
      try {
        const { data } = (await QuickBookRepository.getConnectionStatus()).data
        this.connectionStatus = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getAuthUrl() {
      try {
        const { data } = (await QuickBookRepository.getAuthUrl()).data
        return data
      } catch (e) {
        this.convertAndNotifyError(e)
        return false
      }
    },
    async generateToken(authCode, realmId) {
      try {
        this.loading = true
        const payload = {
          authCode,
          realmId,
        }
        await QuickBookRepository.generateToken(payload)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async initTokenGenerate() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      if (urlSearchParams.get('code') && urlSearchParams.get('realmId')) {
        await this.generateToken(urlSearchParams.get('code'), urlSearchParams.get('realmId'))
      }
    },
    async toggleCustomerIntegrationStatus() {
      this.loadingBtnCustomerActivate = true
      try {
        const payload = {
          is_active: !this.connectionStatus.customer_integration_status,
        }
        const res = await QuickBookRepository.activateMerchant(payload)
        if (res.status === 200) {
          await this.getConnectionStatus()
          this.showSuccessMessage(`Customer integration ${payload.is_active ? 'activated' : 'deactivated'}`)
        } else {
          this.showErrorMessage(`Couldn't ${payload.is_active ? 'activate' : 'deactivate'} customer integration`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnCustomerActivate = false
    },
    async toggleSupplierIntegrationStatus() {
      this.loadingBtnSupplierActivate = true
      try {
        const payload = {
          is_active: !this.connectionStatus.supplier_integration_status,
        }
        const res = await QuickBookRepository.activateWarehouse(payload)
        if (res.status === 200) {
          await this.getConnectionStatus()
          this.showSuccessMessage(`Supplier integration ${payload.is_active ? 'activated' : 'deactivated'}`)
        } else {
          this.showErrorMessage(`Couldn't ${payload.is_active ? 'activate' : 'deactivate'} supplier integration`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnSupplierActivate = false
    },
    async syncMerchants() {
      this.loadingBtnMerchant = true
      try {
        const res = await QuickBookRepository.syncMerchants()
        if (res.status === 200) {
          this.showSuccessMessage('Merchants Synced Successfully!')
        } else {
          this.showErrorMessage('Couldn\'t sync merchants!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnMerchant = false
    },
    async syncWarehouses() {
      this.loadingBtnWarehouse = true
      try {
        const res = await QuickBookRepository.syncWarehouses()
        if (res.status === 200) {
          this.showSuccessMessage('Warehouses Synced Successfully!')
        } else {
          this.showErrorMessage('Couldn\'t sync warehouses!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnWarehouse = false
    },
    async syncDeposits() {
      this.loadingBtnDeposit = true
      try {
        const res = await QuickBookRepository.syncDeposits()
        if (res.status === 200) {
          this.showSuccessMessage('Deposits Synced Successfully!')
        } else {
          this.showErrorMessage('Couldn\'t sync deposits!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnDeposit = false
    },
    async syncInvoices() {
      this.loadingBtnInvoice = true
      try {
        const res = await QuickBookRepository.syncInvoices()
        if (res.status === 200) {
          this.showSuccessMessage('Invoices Synced Successfully!')
        } else {
          this.showErrorMessage('Couldn\'t sync invoices!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnInvoice = false
    },
    async syncAccountsAndServices() {
      this.loadingBtnSyncAccounts = true
      try {
        const res = await QuickBookRepository.syncAccountsAndServices()
        if (res.status === 200) {
          await this.getConnectionStatus()
          this.showSuccessMessage('Accounts Opened And Services Initialized Successfully!')
        } else {
          this.showErrorMessage('Couldn\'t open accounts!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loadingBtnSyncAccounts = false
    },
  },
}
</script>

<style>
  .join-img {
    width: 200px;
  }

  @media screen and (max-width: 1700px) {
    .join-img {
      width: 160px;
    }
  }

  @media screen and (max-width: 576px) {
    .join-img {
      width: 125px;
    }
  }
</style>
